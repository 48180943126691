// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-RSgNb"

const variantClassNames = {lDMGdoCyG: "framer-v-1yyarwq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, TFwDj8FMd: click ?? props.TFwDj8FMd} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TFwDj8FMd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "lDMGdoCyG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap13bl037 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TFwDj8FMd) {const res = await TFwDj8FMd(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1yyarwq", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"lDMGdoCyG"} onTap={onTap13bl037} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-lw3a2a"} layout={"position"} layoutDependency={layoutDependency} layoutId={"lq5CGZpFb"} opacity={1} style={{backgroundColor: "var(--token-6ba06e6b-fdc9-4ffb-9d96-dab5421e4e3b, rgb(255, 255, 255))"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 2\"></svg>"} svgContentId={8751715069} withExternalLayout/><SVG className={"framer-kjge3q"} layout={"position"} layoutDependency={layoutDependency} layoutId={"cyioz3vSt"} opacity={1} style={{backgroundColor: "var(--token-6ba06e6b-fdc9-4ffb-9d96-dab5421e4e3b, rgb(255, 255, 255))"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 2\"></svg>"} svgContentId={8751715069} withExternalLayout/><SVG className={"framer-23jkrf"} layout={"position"} layoutDependency={layoutDependency} layoutId={"nIaqgRbBq"} opacity={1} style={{backgroundColor: "var(--token-6ba06e6b-fdc9-4ffb-9d96-dab5421e4e3b, rgb(255, 255, 255))"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 13 2\"></svg>"} svgContentId={12764534663} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RSgNb.framer-1o6chm6, .framer-RSgNb .framer-1o6chm6 { display: block; }", ".framer-RSgNb.framer-1yyarwq { cursor: pointer; height: 24px; overflow: visible; position: relative; width: 34px; }", ".framer-RSgNb .framer-lw3a2a { flex: none; height: 2px; left: calc(47.05882352941179% - 20px / 2); position: absolute; top: 5px; width: 20px; }", ".framer-RSgNb .framer-kjge3q { flex: none; height: 2px; left: calc(47.05882352941179% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 20px; }", ".framer-RSgNb .framer-23jkrf { bottom: 5px; flex: none; height: 2px; position: absolute; right: 8px; width: 13px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 34
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TFwDj8FMd":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW0KahN76z: React.ComponentType<Props> = withCSS(Component, css, "framer-RSgNb") as typeof Component;
export default FramerW0KahN76z;

FramerW0KahN76z.displayName = "menu";

FramerW0KahN76z.defaultProps = {height: 24, width: 34};

addPropertyControls(FramerW0KahN76z, {TFwDj8FMd: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerW0KahN76z, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})